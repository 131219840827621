
import React from 'react'
import { Barra } from './Barra'

import Lucas from '../media/lucas.png'
import Carla from '../media/carla.png'

export const SobreNosotros = () => {
  return (
    <div className='SobreNosotros'>
      <Barra />
      <h1>SOBRE NOSOTROS</h1>
      <p>Somos dos amigos apasionados por hablar y divulgar.</p>
      <div className="perfil">
        <img src={Lucas} alt="Logo" />
        <div className="texto">
          <h2>Hola buenas, soy <span>Lucas</span></h2>
          <p>Soy programador</p>
        </div>
      </div>

      <div className="perfil">
        <img src={Carla} alt="Logo" />
        <div className="texto">
          <h2>Hola buenas, soy <span>Carla</span></h2>
          <p>Soy Bailarina</p>
        </div>
      </div>

    </div>
  )
}
