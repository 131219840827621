import './App.css';
import { Barra } from './componentes/Barra';
import { Contenido } from './componentes/Contenido';

function App() {
  return (
    <div className="App">
      <Barra />
      <Contenido />
    </div>
  );
}

export default App;
