import React from 'react'
import { useNavigate } from 'react-router-dom';
import Logo from '../media/logo.png'

export const Barra = () => {

  const navigate = useNavigate();

  const clickInicio = () => { navigate('/'); };
  const clickNosotros = () => { navigate('/nosotros/'); };
  const clickPodcast = () => { navigate('/podcast/'); };
  const clickVideos = () => { navigate('/videos/'); };

  return (
    <div className='Barra'>
        <button onClick={clickInicio}>Inicio</button>
        <button onClick={clickNosotros}>Sobre Nosotros</button>
        <img src={Logo} alt="Logo" />
        <button onClick={clickPodcast}>Podcast</button>
        <button onClick={clickVideos}>Nuestros Vídeos</button>
    </div>
  )
}
